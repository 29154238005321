#streamingLive #hero {
  background-color: #000;
  background-image: url('../../public/images/streamingLive/home-hero.png');
  background-size: cover;
  color: #FFF;
  text-shadow: 4px 2px 2px #000;
  padding-bottom: 100px;
  padding-top: 20px;
  background-position-x: center;
  background-position-y: center;
}

#streamingLive #hero h1 {
  margin-top: 100px;
  font-size: 60px;
  color: #24b9ff;
}

#streamingLive #hero p {
  font-size: 40px;
  margin-bottom: 50px;
  margin-top: 0;
}

#streamingLive #hero .btn {
  text-shadow: none;
}

#streamingLive #benefits {
  padding-top: 70px;
  padding-bottom: 70px;
}

#streamingLive #features {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #24b9ff;
  color: #FFF;
}

#streamingLive #features a,
#streamingLive #features a:hover {
  color: #FFF;
  text-decoration: underline;
}

#streamingLive #features li {
  margin-top: 20px;
}