#b1 #hero .title {
  margin-top: 30px;
  font-size: 18px;
  margin-bottom: 0px;
}

#b1 .title {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #1b75bc;
  font-weight: bold;
}

#b1 .homeSection .title {
  color: #1b75bc;
}

#b1 .homeSection .title span {
  color: #1b75bc;
}

#b1 .homeSection.alt h2 span {
  color: #299ffc;
}

#b1 .homeSection.alt .title {
  color: #299ffc;
}

#b1 #register {
  padding-top: 40px;
  padding-bottom: 80px;
}

#b1 h2 span {
  color: #1b75bc;
}

#b1 #registerBox {
  background-color: #EEE;
  border: 1px solid #CCC;
  border-radius: 5px;
  padding: 20px;
}

#b1 #registerBox .MuiTextField-root {
  background-color: #FFF;
}

#b1 a {
  color: #1b75bc;
  text-decoration: none;
}

#b1 a.MuiButton-contained {
  color:#FFFFFF;
}